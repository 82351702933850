@media only screen and (min-width: 1200px) {
	.border-xl-left { border-left: 1px solid rgb(0 0 0 / 10%); }
}
/* @media only screen and (min-width: 1300px) {
	.container{max-width:1200px;}
} */
@media only screen and (min-width: 1480px) {
	.container-fluid{max-width:1400px;}
	.container{max-width:1400px;}
}

@media only screen and (min-width: 1680px) {
	.container-fluid{ max-width:1630px; }
	.container{max-width:1630px;}
}

@media screen and (min-width:575px) {
	.custom-modal-width { max-width: 991px; margin: 6.75rem auto; }
}







@media only screen and (max-width: 1680px) {
	ul.rental-steps-wrap li { margin-left:40px; }
	.pickup-location-box-wrap .pickup-location-input { width: 255px; }
	.pickup-location-box-wrap .pickup-location-input.group-input { width: 280px; }
	.pickup-location-offer { padding: 14px 35px; }
	.pickup-location-box-wrap .show-offer-btn-wrap { width: 160px; }
	.offer-filter-wrap h3 { margin-right: 30px; }
	.offer-filter-wrap .filter-box { max-width: 160px; }
	.vehicle-box-design { max-width: 321px;}
	.vehicle-box-design .vehicle-image-main { width: 100%; height: auto; }
	.featured-rent-price-radio .featured-rent-radio + .featured-rent-radio { margin-left: 20px; }
	.feature-vehicle-box .feature-icon + .feature-icon { margin-left: 10px; padding-left: 10px; }
	.feature-vehicle-box .feature-icon img { margin-right: 5px; }

}
@media only screen and (max-width: 1479px) {
	.select-wrap-dropdown.select-wrap-section-main .return-pickup-wrap { max-width: 114px; }
	.select-wrap-dropdown.select-wrap-section-main .select-wrap-section { padding-left: 10px;max-width: calc(100% - 120px);width:100%;}
	.search-box-wrap .select-wrap-dropdown a {
		max-width: 120px;
		overflow: hidden;
		-o-text-overflow: ellipsis;
		   text-overflow: ellipsis;
		white-space: nowrap;
	}
	.search-box-wrap .select-wrap-dropdown .pickup-return-date-wrap > * { padding: 0 5px; }
	.download-app-wrap > a + a { margin-left: 20px; }
	.subscriber-container form{max-width: 530px;}
	.search-box-wrap .select-wrap-dropdown:nth-child(2) {
		padding: 0 5px;
		max-width: 330px;
	}
	.pickup-return-date-wrap label {
		font-size: 14px;
		line-height: 1.3;
	}
	.search-box-wrap .select-wrap-dropdown a {
		font-size: 14px;
	}
	.search-box-wrap .select-wrap-dropdown:nth-child(3) {
		padding-left: 10px;
		margin-left: 10px;
		max-width: 150px;
	}
}
@media only screen and (max-width: 1400px) {
	.download-app-section { padding: 48px 0 18px 0; }
	ul.nos-vehicle-list li { width:30%; }
	ul.nos-vehicle-list li:nth-child(n + 3) { margin-top: 10px; }
	ul.nos-vehicle-list li label { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
	ul.nos-vehicle-list li:nth-child(4n),ul.nos-vehicle-list.lease-tenure-radio li:nth-child(4n) { margin-left: 0; }
	.rental-detail-place { -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; }
	.pickup-location-box-wrap .pickup-location-input,.pickup-location-box-wrap .pickup-location-input.group-input {width: 49%;}
	
	.have-promocode-link { text-align: left; }
	.offer-filter-wrap h3 {
		width: 100%;
		margin-bottom: 10px;
		font-size: 22px;
		line-height: 22px;
	}
	.offer-filter-wrap .filter-box { max-width: 158px; }
	.filter-box { padding: 8px 15px; }
	.vehicle-box-detail-wrap { max-width: calc(100% - 30px); }
	.vehicle-box-design {
		max-width: calc(25% - 12px);
		margin-left: 6px;
		margin-right: 6px;
		margin-top: 25px;
	}
	.vehicle-box-detail-wrap{margin-top: 25px;max-width: calc(100% - 12px);}
	.charges-option-up p { width: 100%; margin-bottom: 10px; }
	.feature-vehicle-box .feature-icon + .feature-icon { margin-left: 7px; padding-left: 7px; }
	.feature-vehicle-box .feature-icon img { margin-right: 3px; }
	.featured-rent-price-radio .featured-rent-radio { width: 100%; }
	.featured-rent-price-radio .featured-rent-radio + .featured-rent-radio { margin-left: 0px; margin-top: 20px; }
	.vehicle-detail-slider img { width: 90%; height: auto; }
	.featured-rent-radio label span { font-size: 20px; line-height: 25px; }

	.vehicle-design-upgrade-box .vehicle-box-design { max-width: 100%; }
	.border-md-top-0  {
		border-top: 0px !important;
	}

	.banner-slider-section .slick-arrow{left: 50px;}
	.banner-slider-section .slick-arrow.next-arrow{right:50px;}
	.save-cookies-content p{margin-right: 0;margin-bottom: 10px;text-align: center;}
	.charges-option-per-day { width: 100%; }
}	



@media only screen and (max-width: 1199px) {
	h2, .h2{font-size: 36px;line-height: 44px;}
	h3, .h3 { font-size: 32px; line-height: 39px; }
	h2.border-botom, .h2.border-botom { padding-bottom: 15px; }
	.ptb-80 { padding: 60px 0; }
	.ptb-100{padding:80px 0;}
	.primary-bttn { padding: 15px 40px; }
	.find-location-bttn { padding: 16px 38px; }
	.product-nav-tabs .nav-link { font-size: 16px; line-height: 19px; padding: 10px 28px; }
	.logo img { max-width: 230px; height: auto; }
	header.header-nav ul.navbar-nav > li + li { margin-left: 15px; }
	.special-offer-content { font-size: 18px; line-height: 24px; }
	.special-offer-content i:first-child { max-width: calc(100% - 50px); }
	.vehicle-box-design { max-width: calc(33.33% - 12px); }
	.offer-box-wrapper .vehicle-box-design:nth-child(n + 4) { -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; }
	ul.rental-steps-wrap li { margin-left: 20px; }
	ul.rental-steps-wrap a .rental-steps-title { margin-right: 20px; }
	ul.rental-steps-wrap a:before {
		width: 32px;
		height: 32px;
		line-height: 32px;
		font-size: 15px;
	}
	ul.rental-steps-wrap a { font-size: 18px; line-height: 22px; }
	ul.rental-steps-wrap li img { margin-left: auto; margin-right: 15px; }
	.rental-detail-place { font-size: 14px; line-height: 22px; }
	.rental-steps-content.rental-steps-current { font-size: 16px; line-height: 22px; }
	.pickup-location-box-wrap { padding: 20px 30px; }
	input.input-custom.input-location { background-size: 14px auto; }
	input.input-custom { padding: 12px 40px; font-size: 16px; line-height: 20px; }
	.pickup-location-offer { padding: 10px 30px; }
	.offer-filter-wrap h3 { font-size: 20px; line-height: 20px; }
	.offer-filter-wrap .filter-box:nth-child(7) { margin-left: 0; }
	.offer-filter-wrap .filter-box { max-width: 19%; }
	.offer-filter-wrap .filter-box:nth-child(n + 7) { margin-top: 13px; }
	.vehicle-box-detail-wrap { -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
	.vehicle-box-detail-right-content h4 { font-size: 28px; line-height: 34px; }
	.included-wrapper-featured,.featured-rent-price-radio { padding-top: 18px; margin-top: 18px; }
	.book-now-btttn, .book-now-btttn:hover { padding: 10px; }
	.navbar-expand-lg ul.submenu-list-wrap > li > a { padding-right: 0; }
	.book-now-all-bttns-wrap .book-now-all {
		width: 46px;
		height: 46px;
		line-height: 44px;
		font-size: 18px;
		border-radius: 10px;
	}
	.book-now-all-bttns-wrap .book-now-all.book-now-whatsapp { line-height: 39px; }
	.upgrade-option-modal-content .offer-box-wrapper .upgrade-slider-box-wrap { max-width: 46%; }
	.border-xl-left{border-top:1px solid rgb(0 0 0 / 10%);}
	/*new work*/
	#partner-a .nav-link{
		min-width: 167px;
	}
	#rentalprice .border-xl-left { border-top: 1px solid rgb(0 0 0 / 10%); }
 	.bor-0{ border: 0px !important }

	
	.NearByLocation__currentLocation{font-size: 16px;line-height: 20px;}
	.NearByLocation__currentLocation img { max-width: 20px; height: auto; }
	.StationHistory__title { font-size: 16px; }
	.StationDetails__wrapper h3.StationDetails__headline { font-size: 22px; line-height: 27px; }
	.OpeningHours__openingDay { font-size: 15px; line-height: 18px; }
	.pickup-dropdown-box-design { left: -24px !important; }
	 
}


@media only screen and (max-width: 991px) {
	h2, .h2{font-size: 32px;line-height: 40px;}
	h3, .h3 { font-size: 30px; line-height: 37px; }
	h4, .h4 { font-size: 28px; line-height: 34px; }
	p, .p { font-size: 20px; line-height: 24px; }
	h2.border-botom:after, .h2.border-botom:after { width: 50px; }
	.mb-50 { margin-bottom: 40px; }

	.lease-wrapper .lease-left,.lease-wrapper .lease-right {
		-ms-flex: 0 0 100%;
		-webkit-box-flex: 0;
		        flex: 0 0 100%;
		max-width: 100%;
	}
	ul.nos-vehicle-list li { width: auto; }
	ul.nos-vehicle-list li:nth-child(n + 3){margin-top:0;}
	ul.nos-vehicle-list li:nth-child(n + 3){margin-left: 8px;}
	ul.nos-vehicle-list.lease-tenure-radio li:nth-child(4n){margin-left: 15px;}
	.more-car-slider-wrap:before, .more-car-slider-wrap:after { width: 100px; }
	.download-app-wrap > a + a { margin-left: 35px; }
	.download-app-wrap a span.download-text { margin: 0 8px; }
	.download-app-wrap .download-app-anchor { margin-left: 40px; padding: 10px 0 10px 40px; }
	.download-app-wrap a{font-size: 16px;line-height: 22px;}

	.find-location-bttn {
		padding: 14px 34px;
		font-size: 16px;
		line-height: 16px;
	}
	footer{padding: 40px 0;}

	.discount-sticker { font-size: 14px; line-height: 14px; }
	.discount-sticker span { font-size: 22px; line-height: 22px; }
	.special-offer-title {
		font-size: 20px;
		line-height: 24px;
		padding: 8px 10px;
	}
	.product-nav-tabs { padding-bottom: 20px; margin-bottom: 20px; }
	.special-offer-figcaption{-webkit-transform: translate3d(0,calc(100% - 38px),0);transform: translate3d(0,calc(100% - 38px),0);}

	header.header-nav button.navbar-toggler { -webkit-box-ordinal-group: 1; -ms-flex-order: 0; order: 0; }
	a.logo { -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; }
	.reguster-right-wrap { -webkit-box-ordinal-group: 3; -ms-flex-order: 2; order: 2; }
	header.header-nav button.navbar-toggler { padding: 0; border: 0; }


	.navbar-collapse {
		z-index: 9999;
		position: fixed;
		top: 110px;
		width: 100%;
		background-color: #ffffff;
		padding: 20px 10px;
		left: 0;
		right: 0;
	}
	.navbar-collapse ul.navbar-nav {max-width: 690px;margin: 0 auto;}
	.navbar-collapse ul.navbar-nav li.nav-item { width: 100%;margin: 5px 0;}
	header.header-nav ul.navbar-nav li + li { margin-left: 0; }
	body.body-sticky-top .navbar-collapse { top: 70px; }

	.header-invert .call-header { -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; }
	.vehicle-box-design { padding: 20px 13px; }
	.offer-filter-wrap .filter-box { max-width: 23%; }
	.offer-filter-wrap .filter-box:nth-child(n + 6) { margin-left: 10px; margin-top: 10px; }
	.offer-filter-wrap .filter-box:nth-child(6) { margin-left: 0; }
	.vehicle-box-detail-wrap .vehicle-box-detail-left, .vehicle-box-detail-wrap .vehicle-box-detail-right {
		-ms-flex: 0 0 100%;
		-webkit-box-flex: 0;
		        flex: 0 0 100%;
		max-width: 100%;
	}
	.charges-option-up p span { font-size: 20px; line-height:20px; }
	.feature-vehicle-box .feature-icon + .feature-icon { margin-left: 4px; padding-left: 4px; }
	ul.rental-steps-wrap a:before { -webkit-box-flex: 0; -ms-flex: 0 0 auto; flex: 0 0 auto; }
	ul.rental-steps-wrap { -ms-flex-wrap: nowrap; flex-wrap: nowrap; }
	.header-invert .toggle-icon span { width: 22px; margin: 4px auto; }
	.header-invert .reguster-right-wrap .country-dropdown { margin-right: 0; }
	.header-invert .call-header span { max-width: 100px; margin-right: 20px; }
	.footer-invert ul.footer-navigation{width: 100%;}
	footer.footer-invert .footer-wrap,.footer-invert ul.footer-navigation { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
	.footer-wrap .footer-logo { margin-bottom: 20px; }
	ul.footer-navigation li { margin-bottom: 10px; }
	footer.footer-invert { padding: 20px 0; }
	.footer-invert ul.footer-navigation li + li,.footer-invert ul.footer-navigation li {
		margin-left: 20px;
		margin-right: 20px;
	}
	a.logo { padding: 20px 0;margin-right:auto;}
	header.header-nav ul.navbar-nav > li > a { padding: 10px 0; width: 100%; }
	.navbar-expand-lg .navbar-nav li.nav-item ul.submenu-list-wrap {
		position: relative;
		width: 100%;
		max-width: 100%;
	}
	.navbar-expand-lg .navbar-nav li.nav-item ul.submenu-list-wrap{opacity:1;visibility:visible;-webkit-transform: translateY(0%);-ms-transform: translateY(0%);transform: translateY(0%);}
	.navbar-expand-lg .navbar-nav li.nav-item ul.submenu-list-wrap > li > a{position: relative;}
	.navbar-expand-lg .navbar-nav li.nav-item ul.submenu-list-wrap > li > a:before { content: "- "; margin-right: 5px; }
	body.toggle-menu-body { overflow: hidden; }
	.navbar-collapse { overflow-y: auto; overflow-x: hidden; height: calc(100% - 100px); }
	header.header-nav.header-invert{padding:0;}
	ul.navbar-nav > li.nav-item.has-submenu > .submenu-list-wrapper{position: relative;display:none;}
	ul.navbar-nav > li.nav-item.has-submenu.menu-toggle > .submenu-list-wrapper{display:block;-webkit-box-shadow: none;box-shadow: none;}
	.top-left-menu-wrapper { top: 74px; }
	.header-left-menu-section .row > div:nth-child(3) { border-left: 0; }
	.header-left-menu-section .row > div:nth-child(n + 3) { margin-top: 30px; }
	.upgrade-option-modal-content .offer-box-wrapper .upgrade-slider-box-wrap { max-width: 100%; }

	.vehicle-box-design-book { padding: 10px 30px; }
	.upgrade-option-modal-content h2 {
		font-size: 24px;
		line-height: 24px;
	}
	.vehicle-box-detail-right { margin-top: 20px; }
	.pickup-location-flex-content {
		width: 100%;
		margin-top: 10px;
	}
	.box-shadow-white-box { padding: 15px 25px; }
	.custom-input-type-file-wrap label { -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; }
	/*new work*/
	.flex-reversea{
		-ms-flex-wrap: wrap-reverse;
		    flex-wrap: wrap-reverse;
	}
.flex-reverseaa{
		-ms-flex-wrap: wrap-reverse;
		    flex-wrap: wrap-reverse;
	}
	.contactusform-right{
		margin-bottom: 15px;
	}
	.flex-reversea img{
		border-radius: 15px;
		    -o-object-fit: cover;
		       object-fit: cover;
		        width: 100%;
 
	}
	#partner .pickup-location-box{
		padding: 15px 20px;
	}
	#partner-a li{
		width: 100%;
		margin: 0px !important;
	}
	#partner-a h5 {
    font-size: 28px;
    color: #000;
}
#partner-a p {
    font-size: 14px;
    margin-top: 10px !important;
    color: #000;
}
.linka, .linka:hover {
    color: #C8102E;
    font-size: 14px;
    margin-top: 10px;
}
#rentalprice .pickup-location-flex-content {
     width: auto; 
    margin-top: 10px;
}#my-mob p
{
	padding-bottom: 10px;
}
#includea ul.check-box-listing li:last-child{
	padding-bottom: 30px;
}
	.border-topshow {
    border-top: 1px solid rgb(0 0 0 / 10%) !important
}
.border_r {
    border-right: 0px;
    border-bottom: 1px solid #525366;
    /* margin-top: 10px; */
    padding-bottom: 15px;
}
.pickup-dropdown-box-design { left: -34px !important;  }
.pickup-dropdown-box-left { padding: 30px 25px; }
.StationDetails__wrapper { padding: 28px 30px; }
.search-box-wrap .select-wrap-dropdown:nth-child(1) {
    padding-right: 10px;
    width: 55%;
}
	.search-box-wrap .select-wrap-dropdown:nth-child(2) {
		padding: 0 10px;
		max-width: 45%;
	}
	.search-box-wrap .select-wrap-dropdown:nth-child(3) {
		max-width: 180px;
		padding-left: 10px;
		margin: 0;
		margin-top: 20px;
		border-left: 0;
	}
	.search-box-wrap { justify-content: center; }
	.search-box-wrap button.search-bttn { margin-left: 0; }
	.search-box-wrap button.search-bttn { margin-top: 20px; }
	.select-wrap-dropdown.select-wrap-section-main .select-wrap-section { max-width: calc(100% - 134px); }
	.select-wrap-dropdown.select-wrap-section-main .select-wrap-section { max-width: 50%; text-align: center; }
}
@media only screen and (max-width: 768px) {
.pad-10{
 	padding-top: 30px;
 }
	.pad-mob10{
		padding: 0px 20px;	
	}
	.ml-0px .rental-steps-wrap{
	padding: 5px 0 15px !important;	
	}
	#id4 h5 {
    margin-top: 30px;
}#id4 li {
    margin-top: 10px;
    margin-right: 0px;
}#id6 h3 {
    font-size: 28px;
    margin-top: 15px;
    /* margin-left: 36px; */
}#id6 span{
margin-top:10px;
margin-bottom:18px;
}
	
	#id2 img {
    margin-top: 30px;
    height: 64px;
    width: 80px;
}
.pad-10 {
    padding-top: 30px;
}
.spanrange {
 
    top: 85px;
}
  #i1 input{
        width: 100%;
    }
   #i1 img {
     margin-left: 0px; 
}   #i1 .item2 a,   #i1 .item3 a{
	width: 100%;
	margin-left: 0px !important
}
#LoginFormPopup{
	padding: 15px;
}
}
@media only screen and (max-width: 767px) {
	h2, .h2{font-size: 30px;line-height: 38px;}
	h3, .h3 { font-size: 28px; line-height:35px; }
	h4, .h4 { font-size: 26px; line-height: 32px; }
	p, .p { font-size: 18px; line-height: 22px; }
 .pad-10{
 	padding-top: 30px;
 }
	.pad-mob10{
		padding: 0px 20px;	
	}
	#id6 h5 {
    font-size: 30px;
    margin-top: 0px;
}
	#id4 h5 {
    margin-top: 30px;
}#id4 li {
    margin-top: 10px;
    margin-right: 0px;
}#id6 h3 {
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 10px !important
    /* margin-left: 36px; */
}#id6 span{
margin-top:10px;
margin-bottom:18px;
}
	.pad-30 {
    padding: 15px;
}
	#id2 img {
    margin-top: 30px;
    height: 64px;
    width: 80px;
}.primary-bttn { padding: 12px 25px; }
	.ptb-80 { padding: 40px 0; }
	.ptb-100{padding:60px 0;}
	.logo img { max-width: 230px; }
	.info-wrapper { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
	ul.info-list-wrap { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
	ul.info-list-wrap li { width: 50%; }
	ul.info-list-wrap li + li { margin-left: 0; }
	.location-wrap > div { width: 100%; }
	
	.download-app-wrap { -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;padding:0;}
	.download-app-wrap .download-app-anchor {
		border-left: 0;
		margin-left: 0;
		padding-left: 0;
		margin-top: 30px;
		width: 100%;
		text-align: center;
	}
	.download-app-section { padding: 48px 0 10px 0; }
	.special-offer-box .special-offer-box-wrap{min-height: 200px;}
	.special-offer-box .special-offer-box-wrap.special-offer-big { min-height: 450px; }
	.what-new-box-more-content p { max-width: calc(100% - 50px); }
	.search-box-wrap .select-wrap-dropdown:first-child > label, .search-box-wrap .select-wrap-dropdown:first-child > .dropdown-toggle{padding-left:0;}
	.search-box-wrap .select-wrap-dropdown, .search-box-wrap .select-wrap-dropdown:first-child,.search-box-wrap .select-wrap-dropdown:nth-child(3) {
		width: 100%;
		max-width: 100%;
		padding: 0;
	}
	.rental-detail-place { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }

	.lease-left p { margin-bottom: 15px; }
	.lease-left h3 { margin-bottom: 10px; }
	.search-box-wrap { border-radius: 0;padding: 20px 35px;max-width: 450px;}
	.search-box-wrap .select-wrap-dropdown:nth-child(2) { border: 0; padding: 0; }
	.search-box-wrap .select-wrap-dropdown { margin-bottom: 20px; }
	.search-box-wrap .select-wrap-dropdown + .select-wrap-dropdown { border-top: 1.5px solid rgba(0, 0, 0, 0.2);padding-top: 20px;}
	.search-box-wrap .select-wrap-dropdown:nth-child(3) { padding-top: 10px; }
	.more-car-slider-wrap .slick-list.draggable { max-width: 100%; }
	.more-cars-slider img {
		width: 100%;
		height: auto;
		-webkit-transform: scale(1);
		    -ms-transform: scale(1);
		        transform: scale(1);
	}
	.more-cars-slider h4, .more-cars-slider p, .more-car-bttns{opacity:1; visibility:visible;}
	.more-car-slider-wrap:before, .more-car-slider-wrap:after{display:none;}
	.more-car-bttns { -ms-flex-wrap: wrap; flex-wrap: wrap; }
	.more-car-bttns .primary-bttn.primary-invert-bttn { margin: 0 10px; }
	ul.nos-vehicle-list li label { padding: 10px 12px; }
	ul.nos-vehicle-list li:nth-child(n + 5),ul.nos-vehicle-list.lease-tenure-radio li:nth-child(n + 5),ul.nos-vehicle-list.lease-tenure-radio li:nth-child(n + 4){ margin-left: 0; margin-top: 10px; }
	.search-box-wrap button.search-bttn { margin: 0 auto; }
	.banner-section .nav-tabs a + a { margin-left: 40px; }
	.special-offer-content i.fa {
		width: 26px;
		height: 26px;
		line-height: 26px;
		font-size: 16px;
	}
	.special-offer-content i:first-child { max-width: calc(100% - 35px); }


	.navbar-collapse ul.navbar-nav { max-width: 500px; }
	.navbar-collapse { top: 126px; }

	.header-invert .call-header span { display: none; }
	.header-invert .call-header img { max-width: 20px; }
	.vehicle-box-design { max-width: calc(50% - 12px); }
	.offer-box-wrapper .vehicle-box-design:nth-child(n + 4) { -webkit-box-ordinal-group: 1; -ms-flex-order: 0; order: 0; }
	ul.rental-steps-wrap {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		max-width: 270px;
		-webkit-box-pack: start;
		    -ms-flex-pack: start;
		        justify-content: flex-start;
		margin: 0 auto;
	}
	ul.rental-steps-wrap li { margin-left: 0; margin-top: 30px;width: 100%;}
	ul.rental-steps-wrap li:first-child { margin-top: 0; }
	ul.rental-steps-wrap li img { -webkit-transform: rotate( 89deg ); -ms-transform: rotate( 89deg ); transform: rotate( 89deg ); }
	ul.rental-steps-wrap a{ -ms-flex-wrap: wrap; flex-wrap: wrap;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;text-align: center;margin-bottom: 0;}
	ul.rental-steps-wrap a .rental-steps-title,ul.rental-steps-wrap li:last-child span { width: 100%; margin: 10px 20px; }
	ul.rental-steps-wrap li img { margin-right: 0; margin-left: 0; }
	.rental-detail-place > div { text-align: center; }
	.rental-detail-place .rental-detail-place-right { margin-left: 0; margin-top: 10px; }
	.rental-detail-place-two:before { left: 0; right: 0; margin: 0 auto; }
	.offer-filter-wrap .filter-box { max-width: 32%; }
	.offer-filter-wrap .filter-box:nth-child(n + 5){margin-left: 10px;margin-top: 10px;}
	.offer-filter-wrap .filter-box:nth-child(5),.offer-filter-wrap .filter-box:nth-child(8){margin-left: 0;}
	.toggle-icon span { width: 23px; margin: 4px auto; }

	.toggle-icon.cross-toggle span.three { -webkit-transform: rotate( -45deg ) translate(4px, -6px); -ms-transform: rotate( -45deg ) translate(4px, -6px); transform: rotate( -45deg ) translate(4px, -6px); }
	.toggle-icon.cross-toggle span.one { -webkit-transform: rotate( 45deg ) translate(4px, 6px); -ms-transform: rotate( 45deg ) translate(4px, 6px); transform: rotate( 45deg ) translate(4px, 6px); }
	.top-left-menu-wrapper { top: 69px; }
	.header-left-menu-section .row > div:not(:first-child){border-left: 0;}
	.top-left-menu-wrapper { overflow-y: auto; height: calc(100vh - 150px); }
	/*new work*/
	.flex-reversea img{
		border-radius: 15px;
		    -o-object-fit: cover;
		       object-fit: cover;
    height: auto;
	}
	#width-50 .col-lg-2{
		-webkit-box-flex: 0;
		    -ms-flex: 0 0 100%;
		        flex: 0 0 100%;
    max-width: 100%;
	}
#my-mob .rounded-bttn.ml-3{
	margin-left: 0px !important;
	margin-bottom: 10px;
}
#my-moba .d-flex.mx-5{
	margin-left: 0px !important;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}
#rentalprice .pickup-location-flex-content {
     width: 100%; 
    margin-top: 10px;
}
.pickup-dropdown-box-design { left: -50px !important;}
.pickup-dropdown-box-wrapper > div { width: 100%; }
}


@media only screen and (max-width: 575px) {
	h2, .h2{font-size: 28px;line-height: 36px;}
	h3, .h3 { font-size: 26px; line-height:33px; }
	h4, .h4 { font-size: 24px; line-height: 30px; }
	p, .p { font-size: 16px; line-height: 20px; }
	h2.border-botom, .h2.border-botom {
		display: block;
		text-align: center;
	}
	h2.border-botom:after, .h2.border-botom:after {
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	h2.border-botom + p { text-align: center; }
	.primary-bttn {
		padding: 10px 20px;
		font-size: 14px;
		line-height: 14px;
	}
	.special-offer-title { font-size: 18px; line-height: 22px; }
	.more-car-slider-wrap{margin-top: 40px;}

	.discount-sticker { font-size: 12px; line-height: 12px;padding: 8px 12px;}
	.discount-sticker span { font-size: 18px; line-height: 18px; }
	ul.info-list-wrap li { width: 100%; text-align: center; }
	.footer-nav-wrap { text-align: center; }
	ul.social-media-footer { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
	.special-offer-box .special-offer-box-wrap,.special-offer-box .special-offer-box-wrap.special-offer-big{min-height:auto;}
	.special-offer-box .special-offer-box-wrap:before { content: ''; padding-top: 50%; }
	
	.more-car-slider-wrap ul.slick-dots li button { width: 12px; }
	.what-new-box .what-new-box-content { padding: 10px; }
	ul.nos-vehicle-list { max-width: 300px; }
	ul.nos-vehicle-list li:nth-child(odd) { margin-left: 0; }
	ul.nos-vehicle-list li:nth-child(n + 3) { margin-top: 10px; }
	ul.nos-vehicle-list.lease-tenure-radio li:nth-child(even) { margin-left: 15px; }
	ul.nos-vehicle-list.lease-tenure-radio li:nth-child(odd) { margin-left: 0; }
	.special-offer-box .special-offer-box-wrap.special-offer-big p.special-offer-content { display: none; }

	.navbar-collapse ul.navbar-nav { max-width: 100%; padding: 0 10px; }
	.down-arrow { height: 160px; width: 160px; margin-top: -130px; }
	ul.footer-navigation li { width: 100%; text-align: center; }
	.toggle-icon { margin-right: auto; }
	.pickup-location-box-wrap .pickup-location-input, .pickup-location-box-wrap .pickup-location-input.group-input { width: 100%; }
	.pickup-location-box-wrap { padding: 15px 20px; }
	.pickup-location-box-wrap .pickup-location-input { margin-bottom: 25px; }
	.offer-filter-wrap .filter-box:nth-child(n + 4) { margin-top: 10px; }
	.offer-filter-wrap .filter-box:nth-child(even) { margin-left: 0; }
	.offer-filter-wrap .filter-box:nth-child(odd) { margin-left: 5px; }
	.filter-box { padding: 8px 10px; }
	.filter-box.custom-control.custom-switch .custom-control-label::before { right: -46px; }
	.filter-box.custom-control.custom-switch .custom-control-label::after { right: -26px; }
	
	.upgrade-option-modal-content { padding: 20px 13px;border-radius: 10px;}
	.box-shadow-white-box,.grand-total-wrap { padding: 15px 10px !important; }
	.extra-box-range-wrap { -ms-flex-wrap: wrap; flex-wrap: wrap; }
	.extra-box-range-box-content { width: 100%; margin: 10px 0; }
	.input-quantity button{width: 26px;height: 26px;font-size: 12px;}
	.input-quantity input {
		width: 40px;
		font-size: 18px;
		line-height: 26px;
		height: auto;
	}
	.continue-bttn, .continue-bttn:hover{
		padding: 10px 54px;
		font-size: 16px;
		line-height: 22px;
	}
	.save-time-info .media-body { -webkit-box-flex: 100%; -ms-flex: 100%; flex: 100%; margin-top: 10px; }
	.save-time-info { -ms-flex-wrap: wrap; flex-wrap: wrap; }
	.custom-input-type-file-wrap span { margin-left: 0; margin-top: 5px; }
	ul.booking-details-listing .booking-details-listing-left { max-width: 60%; width: 100%; }
	.booking-details-listing-right { max-width: 40%; font-size: 14px; line-height: 19px; }
	.booking-details-listing-right > span { font-size: 22px; line-height: 27px; margin-left: 5px; }
	/*bew work*/
	#partner-a .card {
   
    height: 150px;
  
}
.pickup-dropdown-box-design { left: -10px !important; }
}

@media only screen and (max-width: 530px) {
	.offer-filter-wrap .filter-box { max-width: 49%; }
	.vehicle-box-design { max-width: 100%; }
	section.offer-box-section .offer-box-wrapper { max-width: 380px; margin: 0 auto 50px; }
	.feature-vehicle-box .feature-icon + .feature-icon { margin-left: 8px; padding-left: 8px; }
	.vehicle-box-detail-wrap { padding: 20px 0px; }
	.included-wrapper-featured, .featured-rent-price-radio { padding-top: 15px; margin-top: 15px; }
	.featured-rent-radio label span { font-size: 18px; line-height: 24px; }
	.vehicle-detail-slider-wrap .slide-arrow { width: 30px; height: 30px; line-height: 30px; }
	.vehicle-detail-slider-wrap .slide-arrow.prev-arrow { left: -10px; }
	.vehicle-detail-slider-wrap .slide-arrow.next-arrow { right: -10px; }
	.vehicle-box-detail-right-content h4 { font-size: 26px; line-height: 32px; }
	input.input-custom.input-location,input.input-custom.input-date,input.input-custom.input-time { background-size: 12px auto;background-position: center left 10px;}
	input.input-custom { padding: 12px 30px; }
	
}

@media only screen and (max-width: 430px) {
	.download-app-wrap a{width:100%;text-align: center;}
	.download-app-wrap > a + a{margin-left: 0;margin-top: 15px;}
	.download-app-wrap .download-app-anchor { margin-top: 20px; }
	.more-car-bttns .primary-bttn.primary-invert-bttn {
		max-width: 237px;
		-webkit-box-ordinal-group: 2;
		    -ms-flex-order: 1;
		        order: 1;
		margin: 0;
		width: 100%;
		margin-top: 10px;
	}
	.more-car-bttns .primary-bttn:first-child { margin-right: 15px; }
	.login-register-wrap a { font-size: 0; line-height: 0; }
	.login-register-wrap:before{margin-right: 0;}
	.dropdown.country-dropdown button span { display: none; }
	
	.dropdown.country-dropdown button i { display: inline-block; }
	.dropdown.country-dropdown button { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: end; -ms-flex-align: end; align-items: end; }
	.dropdown.country-dropdown .dropdown-menu.show { right: 0; left: auto; }
	.book-now-all-bttns-wrap .book-now-btttn{max-width:100%;margin-bottom: 10px;}
	.book-now-all-bttns-wrap .book-now-all.book-now-email { margin-left: 0; }
}


@media screen and (max-width: 360px) {
	.gl-btn-login {
		margin: 0;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
	}

	.fb-btn-login {
		float: right;
		margin: 0;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
	}

}

@media screen and (min-width: 768) {
	.first-row-img img {

	    width: -webkit-fill-available;
        margin-left: -32px;
        display: block;
	} 

	.fb-btn-login {
		float: right;
		margin: 0;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
	}

	.gl-btn-login {
		margin: 0;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
	}



}


@media screen and (max-width: 900px) {

	.span-notreg{
		width: -webkit-fill-available;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -moz-text-align-last: center;
         text-align-last: center;
	padding: 10;
	}
	.fb-btn-login {
		float: right;
		margin: 0;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
	}
	.gl-btn-login {
		margin: 0;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
	}
.p-sm-3{
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.btn-login {
		left: -1px;
		width: -webkit-fill-available;
		position: relative;
		color: #fff;
		background-color: #C8102E;
		border-color: #C8102E;
	}

	.form-login .form-control {
		width: -webkit-fill-available;
		font-size: 0.8rem;
	}
	.login-container {
		padding: 25px;
		width: -webkit-fill-available;
		margin: 0 auto;
		margin-top: 50;
		background-color: white;
	}

   .login-first-row{
	background-color: white;
    border-radius: 12px;
    width: -webkit-fill-available;
   }

  .login-text{
	font-size: 35px;
  
    text-align: center;
    position: relative;
 



  }

  .login-card{
	position: relative;
    top: 18px;
    width: -webkit-fill-available;
  }
	.first-row-img img {
		height: 154px;
		width: 137px;
		margin-left: -15px;
		display: none;
	} 


	.form-login {

			margin-top: -26px;
			width: -webkit-fill-available;
			border-radius: 12px 0px 0px 12px;
			margin: 0;
}

	.mainblock-right-img {
		display: none;
	 }
    .spanstyle-subtext {
        position: relative;
        left: -10px;
        font-size: 0.8rem;
        color: #827F7F;
        margin-top: 10px;
        margin-bottom: 10px;
    }

	.span-already-have-account-login{
		position: relative;
		left: -10px;
		font-size: 0.8rem;
		color: #827F7F;
		margin-top: 10px;
		margin-bottom: 10px;
	
	}
	.fb-btn {
		margin: 0 auto;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
	}
	.gl-btn {
		margin: 0 auto;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
	}

	.form-register {
        background-color: white;
    margin-top: 15px;

    border-radius: 12px 0px 0px 12px;
    padding: 18px 20px -7px 25px;

    position: relative;
    left: -3px;
    width: -webkit-fill-available;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
        
    }
}



@media  screen and (min-width: 768px){
	/* .first-row-img img {
		height: 530px;
		width: initial;
		margin-left: -25px;
		border-radius: 12px;
	} */

	.container-fluid .login-container {
		max-width: 1152px;
	}

	.form-login {
		background-color: white;
		margin-top: 22px;
		border-radius: 12px 0px 0px 12px;
		padding: 18px 20px -7px 25px;
		position: relative;
		width: 900;
		/* margin-left: -10px; */
	}


}


@media only screen and (max-width: 1680px) {
	ul.rental-steps-wrap li { margin-left:40px; }
	.pickup-location-box-wrap .pickup-location-input { width: 255px; }
	.pickup-location-box-wrap .pickup-location-input.group-input { width: 280px; }
	.pickup-location-offer { padding: 14px 35px; }
	.pickup-location-box-wrap .show-offer-btn-wrap { width: 160px; }
	.offer-filter-wrap h3 { margin-right: 28px; }
	.offer-filter-wrap .filter-box { max-width: 160px; }
	.vehicle-box-design { max-width: 321px;}
	.vehicle-box-design .vehicle-image-main { width: 100%; height: auto; }
	.featured-rent-price-radio .featured-rent-radio + .featured-rent-radio { margin-left: 20px; }
	.feature-vehicle-box .feature-icon + .feature-icon { margin-left: 10px; padding-left: 10px; }
	.feature-vehicle-box .feature-icon img { margin-right: 5px; }
}

@media only screen and (max-width: 1400px) {
	.download-app-section { padding:25px 0; }
	ul.nos-vehicle-list li { width:30%; }
	ul.nos-vehicle-list li:nth-child(n + 3) { margin-top: 10px; }
	ul.nos-vehicle-list li label { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
	ul.nos-vehicle-list li:nth-child(4n),ul.nos-vehicle-list.lease-tenure-radio li:nth-child(4n) { margin-left: 0; }
	.rental-detail-place { -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; }
	.pickup-location-box-wrap .pickup-location-input,.pickup-location-box-wrap .pickup-location-input.group-input {width: 49%;}
	.have-promocode-link { text-align: left; }
	.offer-filter-wrap h3 {
		width: 100%;
		margin-bottom: 10px;
		font-size: 22px;
		line-height: 22px;
	}
	.offer-filter-wrap .filter-box { max-width: 158px; }
	.filter-box { padding: 8px 15px; }
	.vehicle-box-detail-wrap { max-width: calc(100% - 30px); }
	.vehicle-box-design {
		max-width: calc(25% - 12px);
		margin-left: 6px;
		margin-right: 6px;
		margin-top: 25px;
	}
	.vehicle-box-detail-wrap{margin-top: 25px;max-width: calc(100% - 12px);}
	.charges-option-up p { width: 100%; margin-bottom: 10px; }
	.feature-vehicle-box .feature-icon + .feature-icon { margin-left: 7px; padding-left: 7px; }
	.feature-vehicle-box .feature-icon img { margin-right: 3px; }
	.featured-rent-price-radio .featured-rent-radio { width: 100%; }
	.featured-rent-price-radio .featured-rent-radio + .featured-rent-radio { margin-left: 0px; margin-top: 20px; }
	.vehicle-detail-slider img { width: 90%; height: auto; }
	.featured-rent-radio label span { font-size: 20px; line-height: 25px; }

	.vehicle-design-upgrade-box .vehicle-box-design { max-width: 100%; }
	ul.rental-steps-wrap li:not(:last-child)::after { right: -29px; }
	.promocode-offer-input, .pickup-location-input.group-input { margin-top: 15px; }
}	
.logo img {
    max-width: 230px;
}


@media only screen and (max-width: 1199px) {
	h2, .h2{font-size: 36px;line-height: 44px;}
	h3, .h3 { font-size: 32px; line-height: 39px; }
	h2.border-botom, .h2.border-botom { padding-bottom: 15px; }
	.ptb-80 { padding: 60px 0; }
	.ptb-100{padding:80px 0;}
	.primary-bttn { padding: 15px 40px; }
	.find-location-bttn { padding: 16px 38px; }
	.product-nav-tabs .nav-link { font-size: 16px; line-height: 19px; padding: 10px 28px; }
	.logo img { max-width: 230px; height: auto; }
	header.header-nav ul.navbar-nav > li + li { margin-left: 15px; }
	.special-offer-content { font-size: 18px; line-height: 24px; }
	.special-offer-content i:first-child { max-width: calc(100% - 50px); }
	.vehicle-box-design { max-width: calc(33.33% - 12px); }
	.offer-box-wrapper .vehicle-box-design:nth-child(n + 4) { -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; }
	ul.rental-steps-wrap li { margin-left: 20px; }
	ul.rental-steps-wrap a .rental-steps-title { margin-right: 20px; }
	ul.rental-steps-wrap a:before {
		width: 32px;
		height: 32px;
		line-height: 32px;
		font-size: 15px;
	}
	ul.rental-steps-wrap a { font-size: 18px; line-height: 22px; }
	ul.rental-steps-wrap li img { margin-left: auto; margin-right: 15px; }
	.rental-detail-place { font-size: 14px; line-height: 22px; }
	.rental-steps-content.rental-steps-current { font-size: 16px; line-height: 22px; }
	.pickup-location-box-wrap { padding: 20px 30px; }
	input.input-custom.input-location { background-size: 14px auto; }
	input.input-custom { padding: 12px 40px; font-size: 16px; line-height: 20px; }
	.pickup-location-offer { padding: 10px 30px; }
	.offer-filter-wrap h3 { font-size: 20px; line-height: 20px; }
	.offer-filter-wrap .filter-box:nth-child(7) { margin-left: 0; }
	.offer-filter-wrap .filter-box { max-width: 19%; }
	.offer-filter-wrap .filter-box:nth-child(n + 7) { margin-top: 13px; }
	.vehicle-box-detail-wrap { -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
	.vehicle-box-detail-right-content h4 { font-size: 28px; line-height: 34px; }
	.included-wrapper-featured,.featured-rent-price-radio { padding-top: 18px; margin-top: 18px; }
	.book-now-btttn, .book-now-btttn:hover { padding: 10px; }
	.navbar-expand-lg ul.submenu-list-wrap > li > a { padding-right: 0; }
	.book-now-all-bttns-wrap .book-now-all {
		width: 46px;
		height: 46px;
		line-height: 44px;
		font-size: 18px;
		border-radius: 10px;
	}
	.book-now-all-bttns-wrap .book-now-all.book-now-whatsapp { line-height: 39px; }
	.upgrade-option-modal-content .offer-box-wrapper .upgrade-slider-box-wrap { max-width: 46%; }
	.border-xl-left{border-top:1px solid rgb(0 0 0 / 10%);}
	.input-type-file-image-view { margin-left: 20px; }
	.custom-input-type-wrap input, .custom-input-select-wrap select {
		font-size: 16px;
		line-height: 22px;
		padding: 10px 16px;
	}
	.rounded-bttn { padding: 10px 30px; }
	.custom-input-type-file-wrap span { padding: 12px 50px; }
	#partner-ab .border-xl-left { border-top: 0px !important; }
	#my-a h5{ font-size: 26px; }
	#my-a p { margin-top: 0px !important; }
	#my-a .my-fonta{ margin: 0px 0px  !important; }
	#my-a{ padding-left: 27px; }
	.card-body2{ padding-left: 10px; }
	#a1{ margin-bottom: 10px; }
	.box-title-icon-border{ margin-top: 50px; }
	.card-body2{ text-align: left !important; padding-bottom: 0px; }
	.pickup-location-flex{ -ms-flex-wrap: nowrap; flex-wrap: nowrap; }
	.search-box-wrap .select-wrap-dropdown:nth-child(1) { max-width: 270px;padding-right:15px;}
	.search-box-wrap .select-wrap-dropdown:nth-child(2) { max-width: 360px;padding: 0 15px;}
	.search-box-wrap .select-wrap-dropdown:nth-child(3) { max-width: 150px; padding-left: 15px; width: 100%; }
	.search-box-wrap.promocode-offer-wrap,.search-box-wrap.promocode-offer-wrap .select-wrap-dropdown { max-width: 100%; }
	.search-box-wrap .select-wrap-dropdown a { max-width: 120px; }
	.select-wrap-dropdown.select-wrap-section-main .select-wrap-section { max-width: calc(100% - 114px);padding-left:15px;}
	.search-box-wrap button.search-bttn { padding: 12px 22px; }
	.search-box-wrap button.search-bttn i { margin-right: 10px; }
	.download-app-wrap .download-app-anchor { margin-right: 25px; padding-left: 25px; }
	.slider-brands-logo { width: 100px; height: 100px; }
	.slider-brands-logo img { max-width: 50px; }
	.slider-brands { padding: 0 5px; }
	.subscriber-container form { max-width: 420px; }
	.banner-section { padding-top: 70px; }
	ul.rental-steps-wrap li { max-width: 160px; }
	ul.rental-steps-wrap li .rental-steps-title {
		display: block;
		text-align: center;
		margin-top: 10px;
		width: 100%;
	}
	ul.rental-steps-wrap { -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; }
	.rental-detail-place {-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
	.rental-detail-place .rental-detail-place-right { padding-left: 0; }
}


@media only screen and (max-width: 991px) {
	h2, .h2{font-size: 32px;line-height: 40px;}
	h3, .h3 { font-size: 30px; line-height: 37px; }
	h4, .h4 { font-size: 28px; line-height: 34px; }
	p, .p { font-size: 20px; line-height: 24px; }
	h2.border-botom:after, .h2.border-botom:after { width: 50px; }
	.mb-50 { margin-bottom: 40px; }

	.lease-wrapper .lease-left,.lease-wrapper .lease-right {
		-ms-flex: 0 0 100%;
		-webkit-box-flex: 0;
		        flex: 0 0 100%;
		max-width: 100%;
	}
	ul.nos-vehicle-list li { width: auto; }
	ul.nos-vehicle-list li:nth-child(n + 3){margin-top:0;}
	ul.nos-vehicle-list li:nth-child(n + 3){margin-left: 8px;}
	ul.nos-vehicle-list.lease-tenure-radio li:nth-child(4n){margin-left: 15px;}
	.more-car-slider-wrap:before, .more-car-slider-wrap:after { width: 100px; }
	.download-app-wrap > a + a { margin-left: 35px; }
	.download-app-wrap a span.download-text { margin: 0 8px; }
	.download-app-wrap .download-app-anchor { margin-left: 40px; padding: 10px 0 10px 40px; }
	.download-app-wrap a{font-size: 16px;line-height: 22px;}

	.find-location-bttn {
		padding: 14px 34px;
		font-size: 16px;
		line-height: 16px;
	}
	footer{padding: 40px 0;}

	.discount-sticker { font-size: 14px; line-height: 14px; }
	.discount-sticker span { font-size: 22px; line-height: 22px; }
	.special-offer-title {
		font-size: 20px;
		line-height: 24px;
		padding: 8px 10px;
	}
	.product-nav-tabs { padding-bottom: 20px; margin-bottom: 20px; }
	.special-offer-figcaption{-webkit-transform: translate3d(0,calc(100% - 38px),0);transform: translate3d(0,calc(100% - 38px),0);}

	header.header-nav button.navbar-toggler { -webkit-box-ordinal-group: 1; -ms-flex-order: 0; order: 0; }
	a.logo { -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; }
	.reguster-right-wrap { -webkit-box-ordinal-group: 3; -ms-flex-order: 2; order: 2; }
	header.header-nav button.navbar-toggler { padding: 0; border: 0; }


	.navbar-collapse {
		z-index: 9999;
		position: fixed;
		top: 110px;
		width: 100%;
		background-color: #ffffff;
		padding: 20px 10px;
		left: 0;
		right: 0;
	}
	.navbar-collapse ul.navbar-nav {max-width: 690px;margin: 0 auto;}
	.navbar-collapse ul.navbar-nav li.nav-item { width: 100%;margin: 5px 0;}
	header.header-nav ul.navbar-nav li + li { margin-left: 0; }
	body.body-sticky-top .navbar-collapse { top: 70px; }

	.header-invert .call-header { -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; }
	.vehicle-box-design { padding: 20px 13px; }
	.offer-filter-wrap .filter-box { max-width: 23%; }
	.offer-filter-wrap .filter-box:nth-child(n + 6) { margin-left: 10px; margin-top: 10px; }
	.offer-filter-wrap .filter-box:nth-child(6) { margin-left: 0; }
	.vehicle-box-detail-wrap .vehicle-box-detail-left, .vehicle-box-detail-wrap .vehicle-box-detail-right {
		-ms-flex: 0 0 100%;
		-webkit-box-flex: 0;
		        flex: 0 0 100%;
		max-width: 100%;
	}
	.charges-option-up p span { font-size: 20px; line-height:20px; }
	.feature-vehicle-box .feature-icon + .feature-icon { margin-left: 4px; padding-left: 4px; }
	ul.rental-steps-wrap a:before { -webkit-box-flex: 0; -ms-flex: 0 0 auto; flex: 0 0 auto; }
	ul.rental-steps-wrap { -ms-flex-wrap: nowrap; flex-wrap: nowrap; }
	.header-invert .toggle-icon span { width: 22px; margin: 4px auto; }
	.header-invert .reguster-right-wrap .country-dropdown { margin-right: 0; }
	.header-invert .call-header span { max-width: 100px; margin-right: 20px; }
	.footer-invert ul.footer-navigation{width: 100%;}
	footer.footer-invert .footer-wrap,.footer-invert ul.footer-navigation { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
	.footer-wrap .footer-logo { margin-bottom: 20px; }
	ul.footer-navigation li { margin-bottom: 10px; }
	footer.footer-invert { padding: 20px 0; }
	.footer-invert ul.footer-navigation li + li,.footer-invert ul.footer-navigation li {
		margin-left: 20px;
		margin-right: 20px;
	}
	a.logo { padding: 20px 0;margin-right:auto;}
	header.header-nav ul.navbar-nav > li > a { padding: 10px 0; width: 100%; }
	.navbar-expand-lg .navbar-nav li.nav-item ul.submenu-list-wrap {
		position: relative;
		width: 100%;
		max-width: 100%;
	}
	.navbar-expand-lg .navbar-nav li.nav-item ul.submenu-list-wrap{opacity:1;visibility:visible;-webkit-transform: translateY(0%);-ms-transform: translateY(0%);transform: translateY(0%);}
	.navbar-expand-lg .navbar-nav li.nav-item ul.submenu-list-wrap > li > a{position: relative;}
	.navbar-expand-lg .navbar-nav li.nav-item ul.submenu-list-wrap > li > a:before { content: "- "; margin-right: 5px; }
	body.toggle-menu-body { overflow: hidden; }
	.navbar-collapse { overflow-y: auto; overflow-x: hidden; height: calc(100% - 100px); }
	header.header-nav.header-invert{padding:0;}
	ul.navbar-nav > li.nav-item.has-submenu > .submenu-list-wrapper{position: relative;display:none;}
	ul.navbar-nav > li.nav-item.has-submenu.menu-toggle > .submenu-list-wrapper{display:block;-webkit-box-shadow: none;box-shadow: none;}
	.top-left-menu-wrapper { top: 74px; }
	.header-left-menu-section .row > div:nth-child(3) { border-left: 0; }
	.header-left-menu-section .row > div:nth-child(n + 3) { margin-top: 30px; }
	.upgrade-option-modal-content .offer-box-wrapper .upgrade-slider-box-wrap { max-width: 100%; }

	.vehicle-box-design-book { padding: 10px 30px; }
	.upgrade-option-modal-content h2 {
		font-size: 24px;
		line-height: 24px;
	}
	.vehicle-box-detail-right { margin-top: 20px; }
	.pickup-location-flex-content {
		width: 100%;
		margin-top: 10px;
	}
	.box-shadow-white-box { padding: 15px 25px; }
	.custom-input-type-file-wrap label { -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; }
	.input-type-file-image-view { width: 150px;
	 height: auto; padding: 10px 0px; }
	 #location .rounded-bttn{
	 	width: 100%
	 }
	 #my-a h5{
	 	font-size: 20px;
	 }
	.search-box-wrap { border-radius: 0; }
	.search-box-wrap .select-wrap-dropdown:nth-child(1),.search-box-wrap .select-wrap-dropdown:nth-child(2) {
		max-width: 100%;
		width: 100%;
		border: 0;
	}
	.search-box-wrap .select-wrap-dropdown:nth-child(3) { border: 0; }
	.search-box-wrap .select-wrap-dropdown:nth-child(2) {
		padding: 10px 0;
		margin: 10px 0;
		border-top: 1.5px solid rgba(0, 0, 0, 0.2);
		border-bottom: 1.5px solid rgba(0, 0, 0, 0.2);
	}
	.search-box-wrap { padding: 20px 40px; }
	.search-box-wrap .select-wrap-dropdown a { max-width: 260px; }
	.download-app-wrap {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
	}
	.download-app-wrap > a + a { margin-left: 111px; }
	.download-app-wrap .download-function-anchor { margin-bottom: 20px; }
	.download-app-wrap .download-app-anchor { margin-left: 0; padding-left: 0; border-left: 0; }
	.subscriber-container { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
	.subscriber-container form { margin-top: 15px; }
	.subscriber-red-section { padding: 25px 0; }
	.subscriber-container form input[type="text"] { padding: 14px 175px 16px 30px; }
	.subscriber-container form input[type="submit"] { padding: 8px 20px; top: 6px; }
	.slider-brands { padding: 0 10px; }
	ul.rental-steps-wrap li:not(:last-child)::after { right: -17px;background-size:20px;}
}


@media only screen and (max-width: 767px) {
	h2, .h2{font-size: 30px;line-height: 38px;}
	h3, .h3 { font-size: 28px; line-height:35px; }
	h4, .h4 { font-size: 26px; line-height: 32px; }
	p, .p { font-size: 18px; line-height: 22px; }

	.primary-bttn { padding: 12px 25px; }
	.ptb-80 { padding: 40px 0; }
	.ptb-100{padding:60px 0;}
	.logo img { max-width: 230px; }
	.info-wrapper { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
	ul.info-list-wrap { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
	ul.info-list-wrap li { width: 50%; }
	ul.info-list-wrap li + li { margin-left: 0; }
	.location-wrap > div { width: 100%; }
	
	.download-app-wrap { -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;padding:0;}
	.download-app-wrap .download-app-anchor {
		border-left: 0;
		margin-left: 0;
		padding-left: 0;
		margin-top: 30px;
		width: 100%;
		text-align: center;
	}
	.download-app-section { padding:25px 0; }
	.special-offer-box .special-offer-box-wrap{min-height: 200px;}
	.special-offer-box .special-offer-box-wrap.special-offer-big { min-height: 450px; }
	.what-new-box-more-content p { max-width: calc(100% - 50px); }
	.search-box-wrap .select-wrap-dropdown:first-child > label, .search-box-wrap .select-wrap-dropdown:first-child > .dropdown-toggle{padding-left:0;}
	.search-box-wrap .select-wrap-dropdown, .search-box-wrap .select-wrap-dropdown:first-child,.search-box-wrap .select-wrap-dropdown:nth-child(3) {
		width: 100%;
		max-width: 100%;
		padding: 0;
	}
	.rental-detail-place { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }

	.lease-left p { margin-bottom: 15px; }
	.lease-left h3 { margin-bottom: 10px; }
	.search-box-wrap { border-radius: 0;padding: 20px 35px;max-width: 450px;}
	.search-box-wrap .select-wrap-dropdown:nth-child(2) { border: 0; padding: 0; }
	.search-box-wrap .select-wrap-dropdown { margin-bottom: 20px; }
	.search-box-wrap .select-wrap-dropdown + .select-wrap-dropdown { border-top: 1.5px solid rgba(0, 0, 0, 0.2);padding-top: 10px;}
	.search-box-wrap .select-wrap-dropdown:nth-child(3) { padding-top: 10px; }
	.more-car-slider-wrap .slick-list.draggable { max-width: 100%; }
	.more-cars-slider img {
		width: 100%;
		height: auto;
		-webkit-transform: scale(1);
		    -ms-transform: scale(1);
		        transform: scale(1);
	}
	.more-cars-slider h4, .more-cars-slider p, .more-car-bttns{opacity:1; visibility:visible;}
	.more-car-slider-wrap:before, .more-car-slider-wrap:after{display:none;}
	.more-car-bttns { -ms-flex-wrap: wrap; flex-wrap: wrap; }
	.more-car-bttns .primary-bttn.primary-invert-bttn { margin: 0 10px; }
	ul.nos-vehicle-list li label { padding: 10px 12px; }
	ul.nos-vehicle-list li:nth-child(n + 5),ul.nos-vehicle-list.lease-tenure-radio li:nth-child(n + 5),ul.nos-vehicle-list.lease-tenure-radio li:nth-child(n + 4){ margin-left: 0; margin-top: 10px; }
	.search-box-wrap button.search-bttn { margin: 0 auto; }
	.banner-section .nav-tabs a + a { margin-left: 40px; }
	.special-offer-content i.fa {
		width: 26px;
		height: 26px;
		line-height: 26px;
		font-size: 16px;
	}
	.special-offer-content i:first-child { max-width: calc(100% - 35px); }


	.navbar-collapse ul.navbar-nav { max-width: 500px; }
	.navbar-collapse { top: 126px; }

	.header-invert .call-header span { display: none; }
	.header-invert .call-header img { max-width: 20px; }
	.vehicle-box-design { max-width: calc(50% - 12px); }
	.offer-box-wrapper .vehicle-box-design:nth-child(n + 4) { -webkit-box-ordinal-group: 1; -ms-flex-order: 0; order: 0; }
	ul.rental-steps-wrap {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		max-width: 270px;
		-webkit-box-pack: start;
		    -ms-flex-pack: start;
		        justify-content: flex-start;
		margin: 0 auto;
	}
	ul.rental-steps-wrap li { margin-left: 0; margin-top: 30px;width: 100%;}
	ul.rental-steps-wrap li:first-child { margin-top: 0; }
	ul.rental-steps-wrap li img { -webkit-transform: rotate( 89deg ); -ms-transform: rotate( 89deg ); transform: rotate( 89deg ); }
	ul.rental-steps-wrap a{ -ms-flex-wrap: wrap; flex-wrap: wrap;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;text-align: center;margin-bottom: 0;}
	ul.rental-steps-wrap a .rental-steps-title,ul.rental-steps-wrap li:last-child span { width: 100%; margin: 10px 20px; }
	ul.rental-steps-wrap li img { margin-right: 0; margin-left: 0; }
	.rental-detail-place > div { text-align: center; }
	.rental-detail-place .rental-detail-place-right { margin-left: 0; margin-top: 10px; }
	.rental-detail-place-two:before { left: 0; right: 0; margin: 0 auto; }
	.offer-filter-wrap .filter-box { max-width: 32%; }
	.offer-filter-wrap .filter-box:nth-child(n + 5){margin-left: 10px;margin-top: 10px;}
	.offer-filter-wrap .filter-box:nth-child(5),.offer-filter-wrap .filter-box:nth-child(8){margin-left: 0;}
	.toggle-icon span { width: 23px; margin: 4px auto; }

	.toggle-icon.cross-toggle span.three { -webkit-transform: rotate( -45deg ) translate(4px, -6px); -ms-transform: rotate( -45deg ) translate(4px, -6px); transform: rotate( -45deg ) translate(4px, -6px); }
	.toggle-icon.cross-toggle span.one { -webkit-transform: rotate( 45deg ) translate(4px, 6px); -ms-transform: rotate( 45deg ) translate(4px, 6px); transform: rotate( 45deg ) translate(4px, 6px); }
	.top-left-menu-wrapper { top: 69px; }
	.header-left-menu-section .row > div:not(:first-child){border-left: 0;}
	.top-left-menu-wrapper { overflow-y: auto; height: calc(100vh - 150px); }
	.booking-details-listing-right > span.h4 { font-size: 28px; line-height: 34px; }
	.banner-section { padding-top: 120px; }
	.banner-slider-section .slick-arrow{display:none!important;}
	.download-app-wrap > a + a { margin-left: 35px; }
	.download-app-wrap .download-app-anchor { width: auto; margin-top: 0; }
	.search-box-wrap .select-wrap-dropdown:nth-child(3) {
		width: auto;
		border-top: 0;
		padding-top: 0;
		border-bottom: 0;
		margin-bottom: 0;
	}
	.search-box-wrap .select-wrap-dropdown:nth-child(2) { border-bottom: 1.5px solid rgba(0, 0, 0, 0.2); }
	.search-box-wrap.promocode-offer-wrap .select-wrap-dropdown { margin-bottom: 0; }
	.search-box-wrap .select-wrap-dropdown:nth-child(1) { margin-bottom: 0; }
	.search-box-wrap .select-wrap-dropdown a { max-width: 180px; }
	.search-box-wrap button.search-bttn { padding: 10px 20px; }
	.search-box-wrap .search-box-wrap.promocode-offer-wrap input::-webkit-input-placeholder{font-size: 16px;line-height: 22px;}
	.search-box-wrap .search-box-wrap.promocode-offer-wrap input::-moz-placeholder{font-size: 16px;line-height: 22px;}
	.search-box-wrap .search-box-wrap.promocode-offer-wrap input:-ms-input-placeholder{font-size: 16px;line-height: 22px;}
	.search-box-wrap .search-box-wrap.promocode-offer-wrap input::-ms-input-placeholder{font-size: 16px;line-height: 22px;}
	.search-box-wrap .select-wrap-dropdown a,.search-box-wrap .search-box-wrap.promocode-offer-wrap input, .search-box-wrap .search-box-wrap.promocode-offer-wrap input::placeholder{font-size: 16px;line-height: 22px;}
	.banner-section-content { top: 20px; }
	ul.rental-steps-wrap { max-width: 160px; }
	ul.rental-steps-wrap li:not(:last-child)::after {
		top: auto;
		-webkit-transform: translateY(0) rotate( 88deg );
		    -ms-transform: translateY(0) rotate( 88deg );
		        transform: translateY(0) rotate( 88deg );
		bottom: -20px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	.charges-option-per-day,.charges-option-up p{width:auto;margin-bottom: 0;}
	section.rental-section-mobile{display:block;margin-bottom: 30px;margin-top: 10px;}
	section.rental-steps-section{display:none;}
}


@media only screen and (max-width: 575px) {
	h2, .h2{font-size: 28px;line-height: 36px;}
	h3, .h3 { font-size: 26px; line-height:33px; }
	h4, .h4 { font-size: 24px; line-height: 30px; }
	p, .p { font-size: 16px; line-height: 20px; }
	h2.border-botom, .h2.border-botom {
		display: block;
		text-align: center;
	}
	h2.border-botom:after, .h2.border-botom:after {
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	h2.border-botom + p { text-align: center; }
	.primary-bttn {
		padding: 10px 20px;
		font-size: 14px;
		line-height: 14px;
	}
	.special-offer-title { font-size: 18px; line-height: 22px; }
	.more-car-slider-wrap{margin-top: 40px;}

	.discount-sticker { font-size: 12px; line-height: 12px;padding: 8px 12px;}
	.discount-sticker span { font-size: 18px; line-height: 18px; }
	ul.info-list-wrap li { width: 100%; text-align: center; }
	.footer-nav-wrap { text-align: center; }
	ul.social-media-footer { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
	.special-offer-box .special-offer-box-wrap,.special-offer-box .special-offer-box-wrap.special-offer-big{min-height:auto;}
	.special-offer-box .special-offer-box-wrap:before { content: ''; padding-top: 50%; }
	
	.more-car-slider-wrap ul.slick-dots li button { width: 12px; }
	.what-new-box .what-new-box-content { padding: 10px; }
	ul.nos-vehicle-list { max-width: 300px; }
	ul.nos-vehicle-list li:nth-child(odd) { margin-left: 0; }
	ul.nos-vehicle-list li:nth-child(n + 3) { margin-top: 10px; }
	ul.nos-vehicle-list.lease-tenure-radio li:nth-child(even) { margin-left: 15px; }
	ul.nos-vehicle-list.lease-tenure-radio li:nth-child(odd) { margin-left: 0; }
	.special-offer-box .special-offer-box-wrap.special-offer-big p.special-offer-content { display: none; }

	.navbar-collapse ul.navbar-nav { max-width: 100%; padding: 0 10px; }
	.down-arrow { height: 160px; width: 160px; margin-top: -130px; }
	ul.footer-navigation li { width: 100%; text-align: center; }
	.toggle-icon { margin-right: auto; }
	.pickup-location-box-wrap .pickup-location-input, .pickup-location-box-wrap .pickup-location-input.group-input { width: 100%; }
	.pickup-location-box-wrap { padding: 15px 20px; }
	.pickup-location-box-wrap .pickup-location-input { margin-bottom: 25px; }
	.promocode-offer-input, .pickup-location-input.group-input{margin-top: 0;}
	.offer-filter-wrap .filter-box:nth-child(n + 4) { margin-top: 10px; }
	.offer-filter-wrap .filter-box:nth-child(even) { margin-left: 0; }
	.offer-filter-wrap .filter-box:nth-child(odd) { margin-left: 5px; }
	.filter-box { padding: 8px 10px; }
	.filter-box.custom-control.custom-switch .custom-control-label::before { right: -46px; }
	.filter-box.custom-control.custom-switch .custom-control-label::after { right: -26px; }
	
	.upgrade-option-modal-content { padding: 20px 13px;border-radius: 10px;}
	.box-shadow-white-box,.grand-total-wrap { padding: 15px 10px; }
	.extra-box-range-wrap { -ms-flex-wrap: wrap; flex-wrap: wrap; }
	.extra-box-range-box-content { width: auto; margin: 10px 0; }
	.input-quantity { margin-top: 15px; }
	.input-quantity button{width: 26px;height: 26px;font-size: 12px;}
	.input-quantity input {
		width: 40px;
		font-size: 18px;
		line-height: 26px;
		height: auto;
	}
	.continue-bttn, .continue-bttn:hover{
		padding: 10px 54px;
		font-size: 16px;
		line-height: 22px;
	}
	.save-time-info .media-body { -webkit-box-flex: 100%; -ms-flex: 100%; flex: 100%; margin-top: 10px; }
	.save-time-info { -ms-flex-wrap: wrap; flex-wrap: wrap; }
	.custom-input-type-file-wrap span { margin-left: 0; margin-top: 5px; }
	ul.booking-details-listing .booking-details-listing-left { max-width: 60%; width: 100%; }
	.booking-details-listing-right { max-width: 40%; font-size: 14px; line-height: 19px; }
	.booking-details-listing-right > span { font-size: 22px; line-height: 27px; margin-left: 5px; }
	.custom-input-type-file-wrap span { width: 100%; text-align: center;max-width:320px;}
	.custom-input-type-file-wrap label { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; }
	.input-type-file-image-view { margin: 8px 0; }
	.input-type-file-image-view img { max-width: 70px; }
	.rounded-bttn { padding: 9px 20px; font-size: 16px; line-height: 22px; }
	.bttns-book-pay-wrap { max-width: 320px; margin: 0 auto; }
	ul.rental-policies-list li { margin-right: 6px; padding-right: 6px; }
	.download-app-wrap .download-app-anchor { margin-left: 10px; }
	.banner-section-content { position: relative; top: 0; }
	.banner-section { padding-top: 0; padding-bottom: 30px; }
	.select-wrap-dropdown.select-wrap-section-main .select-wrap-section {
		max-width: 100%;
		padding-left: 0;
		text-align: center;
	}
	.select-wrap-dropdown.select-wrap-section-main .return-pickup-wrap { max-width: 100%; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
	.search-box-wrap .select-wrap-dropdown .pickup-return-date-wrap > div { width: 100%; }
	.pickup-return-date-right:before {
		left: 0;
		right: 0;
		margin: 0 auto;
		top: -6px;
	}
	.pickup-return-date-right { margin-top: 10px; }
	.search-box-wrap .select-wrap-dropdown:nth-child(3) { text-align: center; }
	.search-box-wrap.promocode-offer-wrap input { text-align: center; }
	.search-box-wrap button.search-bttn { margin-top: 10px; }
	.download-app-wrap { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }
	.download-app-wrap .download-function-anchor,.download-app-wrap .download-function-anchor:first-child { margin-left: auto; margin-right: auto; }
	.download-store-app + .download-store-app { margin-left: 0; }
	.download-store-app { margin-top: 13px; }
	.subscriber-container h2 { text-align: center; }
	.rental-steps-mobile-center h4 { font-size: 14px; line-height: 20px; }
	.rental-steps-mobile-center p { font-size: 10px; line-height: 16px; }
	.rental-steps-mobile-center { max-width: 60%;padding-right: 3px;}
	.rental-steps-mobile-left { margin-right: 10px; padding-left: 14px; padding-right: 0; width: 40px; }
	.rental-steps-mobile-right{margin-right: 10px;}
}

@media only screen and (max-width: 530px) {
	.offer-filter-wrap .filter-box { max-width: 49%; }
	.vehicle-box-design { max-width: 100%; }
	section.offer-box-section .offer-box-wrapper { max-width: 380px; margin: 0 auto 50px; }
	.feature-vehicle-box .feature-icon + .feature-icon { margin-left: 8px; padding-left: 8px; }
	.vehicle-box-detail-wrap { padding: 20px 0px; }
	.included-wrapper-featured, .featured-rent-price-radio { padding-top: 15px; margin-top: 15px; }
	.featured-rent-radio label span { font-size: 18px; line-height: 24px; }
	.vehicle-detail-slider-wrap .slide-arrow { width: 30px; height: 30px; line-height: 30px; }
	.vehicle-detail-slider-wrap .slide-arrow.prev-arrow { left: -10px; }
	.vehicle-detail-slider-wrap .slide-arrow.next-arrow { right: -10px; }
	.vehicle-box-detail-right-content h4 { font-size: 26px; line-height: 32px; }
	input.input-custom.input-location,input.input-custom.input-date,input.input-custom.input-time { background-size: 12px auto;background-position: center left 10px;}
	input.input-custom { padding: 12px 30px; }
	
}

@media only screen and (max-width: 430px) {
	.download-app-wrap a{width:100%;text-align: center;}
	.download-app-wrap > a + a{margin-left: 0;margin-top: 15px;}
	.download-app-wrap .download-app-anchor { margin-top: 20px; }
	.more-car-bttns .primary-bttn.primary-invert-bttn {
		max-width: 237px;
		-webkit-box-ordinal-group: 2;
		    -ms-flex-order: 1;
		        order: 1;
		margin: 0;
		width: 100%;
		margin-top: 10px;
	}
	.more-car-bttns .primary-bttn:first-child { margin-right: 15px; }
	.login-register-wrap a { font-size: 0; line-height: 0; }
	.login-register-wrap:before{margin-right: 0;}
	.dropdown.country-dropdown button span { display: none; }
	
	.dropdown.country-dropdown button i { display: inline-block; }
	.dropdown.country-dropdown button { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: end; -ms-flex-align: end; align-items: end; }
	.dropdown.country-dropdown .dropdown-menu.show { right: 0; left: auto; }
	.book-now-all-bttns-wrap .book-now-btttn{max-width:100%;margin-bottom: 10px;}
	.book-now-all-bttns-wrap .book-now-all.book-now-email { margin-left: 0; }
	.promocode-offer-input { max-width: 100%;margin-bottom: 25px;}
	.important-info-start-bg-stick,.important-info-start-stick{font-size: 12px;line-height: 17px;}
}




@media screen and (max-width: 600) {
    .login-container{
        background-color: white;
        margin: 25px;
        padding: 25px;
        width: -webkit-fill-available;
    }
    
    .form-login {
        margin-top: -26px;
        width: 346px;
        border-radius: 12px 0px 0px 12px;
        /* margin-left: 79px; */
        border-radius: 12px;
        margin: 1;
    }

}



@media screen and (max-width: 700px) {


.mainblock-right-img {
    display: none;
 }

   
.btn-register {
	position: relative;

    width: -webkit-fill-available;
    color: #fff;
    background-color: #C8102E;
    border-color: #C8102E;

}

    .form-register {
		background-color: white;
		border-radius: 12px;
	
		margin-top: 15px;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
        
    }
    .tearms-checkbox {
        margin-left: 20px;
        font-size: 10px;
    }

.contactbannerimg{
    padding-left: 0px;
    padding-right: 0px;
    background-color: rgb(255, 255, 255);
}

.contactusform-right{
    
    background-color: black;
    /*height: 300px;*/
  
    position: relative;
    /*top: 30px;
    left: -40px;*/
    padding: 20;
    /*width: 293px;*/
  
}
.imgstyle{
    /* float: right; */
    /* margin-top: -250; */
    width: 290px;
    height: 100px;
    margin-right: 19px;
    -o-object-fit: cover;
       object-fit: cover;
    /* border-radius: 0px 12px 12px 0px; */
    position: relative;
    left: 14px;
    border-radius: unset;
}


}






@media screen and (max-width: 900px) {

.mt-1.d-flex-center{
	margin-bottom: 10px !important
}

	.reg-main-img img {
		width: 500;
		height: 924px;
		display: none;
	}

	.reg-main-img  {
		
		display: none;
	}
    .page-title{
        font-size: 37px;
    }
.topblock{
    background-color: rgb(255, 255, 255);
    padding: 25px;
    border-radius: 12px 12px 0px 0px;
    
}

.midblocks {
    background-color: rgb(255 255 255);
    border-radius: 12px;
 
    height: 306px;
    margin-bottom: 20px;}

    .icon-text {
        width: 216px;
        position: relative;
        left: 55px;
        top: -29px;
    
    }

.block-icon{
    background-color: #F9E5E8;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    top: 12px;
    left: 0px;
    border-radius: 5px;
}
span{
    word-wrap: break-word; 
}
.icon{
    width: 44;
    height: 44;
    background-color: #F9E5E8;
    border-radius: 11px;
}

.icon-image {
    width: 22;
    height: 22;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: relative;
    top: 13px;
}

.socialbtn-row{
	margin: 0 auto;;
}
  }


  @media all and (device-width: 320px) and (device-height: 568px) and (orientation:portrait)
  {


	.socialbtn-row {
		margin: 0 auto;
		margin-left: -11px;
	}


  }

  @media all and (device-width: 280px) and (device-height: 653px) and (orientation:portrait){

	.socialbtn-row {
		margin: 0 auto;
		margin-left: -21px;
	}
  


}


@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait)
 {
    .login-card {
		position: relative;
		top: -12px;
		width: -webkit-fill-available;
		left: 22px;
	}


	.first-row-img img {
		height: 523px;
		width: initial;
		margin-left: -26px;
		border-radius: 12px;
		display: block;
	}
	
	.register-row {
		margin-bottom: 30px;
		background-color: white;
		
	}


	.btn-register {
		position: relative;
		left: -10;
		width: 350;
		color: #fff;
		background-color: #C8102E;
		border-color: #C8102E;
	}

	.gl-btn {
		margin-left: -63px;
	}

	.mainblock-right-img {
    display: block;
    width: 375px;
    height: 700;
    top: 00;
    border-radius: 0px 0px 12px 12px; 
}


	.reg-main-img img {
		width: 500;
		height: 924px;
		display: none;
	}

    
  }




  @media only screen and (max-width:731px) {
    .display-3  {
        font-size:30px;
        padding-top: 5px;
	
}

.btn4{

    margin-right: 85px;
}
iframe{
    width:95%
} 
 #search{
	 margin-top:1.5rem;
	 margin-left: 8rem;
	
	
 }
 
 #id1{
	 margin-left:4rem; 
	 
	  width:25rem;
	
}

 #btn2{
	 margin-left:0.5rem; 
	 
	
}

}
@media only screen and (max-width:768px) {
#LoginFormPopup .modal-dialog {
    max-width: 603px;
    margin: 1.75rem auto;
}	
}

@media only screen and (max-width:640px) {
#search{
	 margin-top:1rem;
	 margin-left: 5rem;
	
	
 }
 
 #id1{
	 margin-left:4rem; 
	  width:20rem;
	
}
#btn2{
	 margin-left:-3rem;
    width:20rem; 
	 
	
}
}